import { getSanityLandingPageSlug } from '@otovo/shared/lib/getSanityLandingPageSlug';
import { StaticPropsContext } from '@otovo/shared/lib/next/types';
import { fetchSanityDocumentWrapper } from '@otovo/shared/lib/sanity/fetchSanityDocumentWrapper';
import {
  Otovo$Config,
  Otovo$StorefrontConfig,
} from '@otovo/shared/types/otovoweb';
import { Sanity$CampaignPage } from '@otovo/shared/types/sanityTypes';
import SanityLandingPage from '../components/SanityLandingPage/SanityLandingPage';
import { landingPageQuery } from '../components/SanityLandingPage/sanityQueries';
import StorefrontPage from '../components/StorefrontPage';
import { getStaticProps as getStaticMarketProps } from '../config/getProps';

type Props = {
  config: Otovo$Config;
  sanityDocument: Sanity$CampaignPage;
};

const Home = ({ config, sanityDocument }: Props) => {
  return (
    <StorefrontPage config={config}>
      <SanityLandingPage page={sanityDocument} />
    </StorefrontPage>
  );
};

export async function getStaticProps(context: StaticPropsContext) {
  return fetchSanityDocumentWrapper({
    context,
    sanityQuery: (config: Otovo$StorefrontConfig) =>
      landingPageQuery(
        'landingPage',
        getSanityLandingPageSlug(config.BU_CONFIG.market),
        config.BU_CONFIG,
        context.locale,
      ),
    getStaticMarketProps,
  });
}

export default Home;
